const BASEBALL_PITCHER = [
  {
    label: 'ERA',
    stats: ['earned_run_average'],
  },
  {
    label: 'W-L',
    stats: ['wins', 'losses'],
    separator: '-',
  },
  {
    label: 'SO',
    stats: ['shut_outs'],
  },
  {
    label: 'WHIP',
    stats: ['walks_and_hits_per_inning'],
  },
];

const BASEBALL_PLAYER = [
  {
    label: 'AVG',
    stats: ['batting_average'],
  },
  {
    label: 'HR',
    stats: ['home_runs'],
  },
  {
    label: 'RBI',
    stats: ['runs_batted_in'],
  },
  {
    label: 'OBP',
    stats: ['on_base_percentage'],
  },
];

const BASKETBALL_PLAYER = [
  {
    label: 'PPG',
    stats: ['points_average'],
  },
  {
    label: 'APG',
    stats: ['assists_average'],
  },
  {
    label: 'RPG',
    stats: ['rebounds_total_average'],
  },
  {
    label: 'MPG',
    stats: ['minutes_average'],
  },
];

const FOOTBALL_QB = [
  {
    label: 'COMP/ATT',
    stats: ['passing_completions', 'passing_attempts'],
    separator: '/',
  },
  {
    label: 'YDS',
    stats: ['passing_yards'],
  },
  {
    label: 'PTD',
    stats: ['passing_touchdowns'],
  },
  {
    label: 'INT',
    stats: ['interceptions'],
  },
];

const FOOTBALL_RB = [
  {
    label: 'RSH',
    stats: ['rushing_attempts'],
  },
  {
    label: 'YDS',
    stats: ['rushing_yards'],
  },
  {
    label: 'AVG',
    stats: ['rushing_yards_average'],
  },
  {
    label: 'LNG',
    stats: ['rushing_long_yards'],
  },
];

const FOOTBALL_RECEIVER = [
  {
    label: 'REC',
    stats: ['receptions'],
  },
  {
    label: 'YDS',
    stats: ['receiving_yards'],
  },
  {
    label: 'TD',
    stats: ['receiving_touchdowns'],
  },
  {
    label: 'FUM(L)',
    stats: 'fumbles_lost',
  },
];

const FOOTBALL_DEFENSE = [
  {
    label: 'TACK',
    stats: ['tackles'],
  },
  {
    label: 'AST',
    stats: ['tackles_assist'],
  },
  {
    label: 'SACK',
    stats: ['sacks'],
  },
  {
    label: 'INT',
    stats: ['interceptions'],
  },
];

const FOOTBALL_KICKER = [
  {
    label: 'FG',
    stats: ['field_goals_made'],
  },
  {
    label: 'FGA',
    stats: ['field_goals_attempted'],
  },
  {
    label: 'XP',
    stats: ['extra_points_made'],
  },
];

const FOOTBALL_PUNTER = [
  {
    label: 'PUNTS',
    stats: ['punts'],
  },
  {
    label: 'YDS',
    stats: ['punts_yards'],
  },
  {
    label: 'AVG',
    stats: ['punts_average'],
  },
];

const HOCKEY_GOALIE = [
  {
    label: 'Record',
    stats: ['wins', 'losses', 'overtime_losses'],
    separator: '-',
  },
  {
    label: 'GAA',
    stats: ['goals_against_average'],
  },
  {
    label: 'SV%',
    stats: ['save_percentage'],
  },
  {
    label: 'Avg. SOG',
    stats: ['shots_against', 'games_played'],
    execute_calculation: true,
    calculation: rawStats => Math.round(rawStats[0] / rawStats[1]),
  },
];

const HOCKEY_SKATER = [
  {
    label: 'G',
    stats: ['goals'],
  },
  {
    label: 'A',
    stats: ['assists'],
  },
  {
    label: '+/-',
    stats: ['plus_minus'],
  },
  {
    label: 'Minutes',
    stats: ['time_on_ice_minutes', 'time_on_ice_seconds'],
    separator: ':',
  },
];

const SOCCER_GOALIE = [
  {
    label: 'GS',
    stats: ['games_started'],
  },
  {
    label: 'SA',
    stats: ['shots_faced'],
  },
  {
    label: 'SVS',
    stats: ['saves'],
  },
  {
    label: 'GA',
    stats: ['goals_allowed'],
  },
];

const SOCCER_PLAYER = [
  {
    label: 'G',
    stats: ['games_goals'],
  },
  {
    label: 'A',
    stats: ['assists'],
  },
  {
    label: 'SHO',
    stats: ['games_shots'],
  },
  {
    label: 'SOT',
    stats: ['games_shots_on_goal'],
  },
];

const SOCCER_DEFENDER = [
  {
    label: 'G',
    stats: ['games_goals'],
  },
  {
    label: 'A',
    stats: ['assists'],
  },
  {
    label: 'TAC',
    stats: ['tackles'],
  },
  {
    label: 'BLK',
    stats: ['blocked_shots'],
  },
];

const SOCCER_MIDFIELDER = [
  {
    label: 'GS',
    stats: ['games_started'],
  },
  {
    label: 'G',
    stats: ['games_goals'],
  },
  {
    label: 'A',
    stats: ['assists'],
  },
  {
    label: 'SHO',
    stats: ['games_shots'],
  },
];

const SOCCER_SUBSTITUTE = [
  {
    label: 'G',
    stats: ['games_goals'],
  },
  {
    label: 'A',
    stats: ['assists'],
  },
  {
    label: 'CRS',
    stats: ['crosses'],
  },
  {
    label: 'COR',
    stats: ['corner_kicks'],
  },
];

export default {
  BASEBALL_PITCHER,
  BASEBALL_PLAYER,
  BASKETBALL_PLAYER,
  FOOTBALL_DEFENSE,
  FOOTBALL_KICKER,
  FOOTBALL_PUNTER,
  FOOTBALL_QB,
  FOOTBALL_RB,
  FOOTBALL_RECEIVER,
  HOCKEY_GOALIE,
  HOCKEY_SKATER,
  SOCCER_GOALIE,
  SOCCER_PLAYER,
  SOCCER_DEFENDER,
  SOCCER_MIDFIELDER,
  SOCCER_SUBSTITUTE,
};
