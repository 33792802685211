const BASEBALL_PITCHER = [
  {
    label: 'IP',
    stats: ['innings_pitched'],
  },
  {
    label: 'H',
    stats: ['hits'],
  },
  {
    label: 'ER',
    stats: ['earned_runs'],
  },
  {
    label: 'K',
    stats: ['strike_outs'],
  },
  {
    label: 'BB',
    stats: ['walks'],
  },
];

const BASEBALL_PLAYER = [
  {
    label: 'H/AB',
    stats: ['hits', 'at_bats'],
    separator: '/',
  },
  {
    label: 'R',
    stats: ['runs'],
  },
  {
    label: 'HR',
    stats: ['home_runs'],
  },
  {
    label: 'RBI',
    stats: ['runs_batted_in'],
  },
  {
    label: 'BB',
    stats: ['walks'],
  },
];

const BASKETBALL_PLAYER = [
  {
    label: 'P',
    stats: ['points'],
  },
  {
    label: 'REB',
    stats: ['rebounds_total'],
  },
  {
    label: 'AST',
    stats: ['assists'],
  },
  {
    label: 'FG',
    stats: ['field_goals_made', 'field_goals_attempted'],
    separator: '/',
  },
];

const FOOTBALL_QB = [
  {
    label: 'Yds',
    stats: ['passing_yards'],
  },
  {
    label: 'C/Att',
    stats: ['passing_completions', 'passing_attempts'],
    separator: '/',
  },
  {
    label: 'TD',
    stats: ['passing_touchdowns'],
  },
  {
    label: 'INT',
    stats: ['passing_interceptions'],
  },
  {
    label: 'RAT',
    stats: ['passing_rating'],
  },
];

const FOOTBALL_RB = [
  {
    label: 'CAR',
    stats: ['rushing_attempts'],
  },
  {
    label: 'Yds',
    stats: ['rushing_yards'],
  },
  {
    label: 'AVG',
    stats: ['rushing_yards_average'],
  },
  {
    label: 'TD',
    stats: ['rushing_touchdowns'],
  },
  {
    label: 'FUML',
    stats: ['fumbles_lost'],
  },
];

const FOOTBALL_RECEIVER = [
  {
    label: 'REC',
    stats: ['receiving_receptions'],
  },
  {
    label: 'TGT',
    stats: ['receiving_targets'],
  },
  {
    label: 'Yds',
    stats: ['receiving_yards'],
  },
  {
    label: 'AVG',
    stats: ['receiving_yards_average'],
  },
  {
    label: 'TD',
    stats: ['receiving_touchdowns'],
  },
];

const FOOTBALL_DEFENSE = [
  {
    label: 'TACK',
    stats: ['defensive_tackles_total'],
  },
  {
    label: 'SACK',
    stats: ['defensive_sacks'],
  },
  {
    label: 'INT',
    stats: ['interceptions'],
  },
  {
    label: 'FUMR',
    stats: ['fumbles_opponent_recovered'],
  },
  {
    label: 'FFUM',
    stats: ['fumbles_forced'],
  },
];

const FOOTBALL_KICKER = [
  {
    label: 'XP',
    stats: ['kicking_extra_points_made'],
  },
  {
    label: 'XPA',
    stats: ['kicking_extra_points_attempted'],
  },
  {
    label: 'FG',
    stats: ['field_goals_made'],
  },
  {
    label: 'FGA',
    stats: ['field_goals_attempted'],
  },
  {
    label: 'LNG',
    stats: ['field_goals_long'],
  },
];

const FOOTBALL_PUNTER = [
  {
    label: 'PUNT',
    stats: ['punts'],
  },
  {
    label: 'Yds',
    stats: ['punts_yards'],
  },
  {
    label: 'AVG',
    stats: ['punts_average'],
  },
  {
    label: 'LNG',
    stats: ['punts_yard_long'],
  },
  {
    label: 'TB',
    stats: ['punts_touchbacks'],
  },
];

const HOCKEY_GOALIE = [
  {
    label: 'W/L',
    stats: ['wins', 'losses'],
    separator: '/',
  },
  {
    label: 'GA',
    stats: ['goals_against'],
  },
  {
    label: 'SA',
    stats: ['shots_against'],
  },
  {
    label: 'SV',
    stats: ['saves'],
  },
  {
    label: 'SV%',
    stats: ['save_percentage'],
  },
];

const HOCKEY_SKATER = [
  {
    label: 'G',
    stats: ['goals'],
  },
  {
    label: 'A',
    stats: ['assists'],
  },
  {
    label: 'PIM',
    stats: ['penalty_minutes'],
  },
  {
    label: '+/-',
    stats: ['plus_minus'],
  },
  {
    label: 'TOI',
    stats: ['time_on_ice_full'],
  },
];

const SOCCER_GOALIE = [
  {
    label: 'GA',
    stats: ['goals_against'],
  },
  {
    label: 'SVS',
    stats: ['saves'],
  },
  {
    label: 'SOT',
    stats: ['shots_on_goal_against'],
  },
];

const SOCCER_PLAYER = [
  {
    label: 'G',
    stats: ['goals'],
  },
  {
    label: 'A',
    stats: ['assists'],
  },
  {
    label: 'SHO',
    stats: ['shots'],
  },
  {
    label: 'SOT',
    stats: ['shots_on_goal'],
  },
];

export default {
  BASEBALL_PITCHER,
  BASEBALL_PLAYER,
  BASKETBALL_PLAYER,
  FOOTBALL_DEFENSE,
  FOOTBALL_KICKER,
  FOOTBALL_PUNTER,
  FOOTBALL_QB,
  FOOTBALL_RB,
  FOOTBALL_RECEIVER,
  HOCKEY_GOALIE,
  HOCKEY_SKATER,
  SOCCER_GOALIE,
  SOCCER_PLAYER,
};
