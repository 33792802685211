const BASEBALL_PITCHER = [
  {
    label: 'IP',
    stats: ['innings_pitched'],
  },
  {
    label: 'H',
    stats: ['hits'],
  },
  {
    label: 'R',
    stats: ['runs'],
  },
  {
    label: 'ER',
    stats: ['earned_runs'],
  },
  {
    label: 'K',
    stats: ['strike_outs'],
  },
  {
    label: 'BB',
    stats: ['walks'],
  },
];

const BASEBALL_PITCHER_PREGAME = [
  {
    label: 'IP',
    stats: ['formatted_innings_pitched'],
  },
  {
    label: 'WHIP',
    stats: ['formatted_walks_plus_hits_per_inning_pitched'],
  },
  {
    label: 'K',
    stats: ['strikeouts'],
  },
  {
    label: 'BB',
    stats: ['walks'],
  },
];

const BASEBALL_PLAYER = [
  {
    label: 'H/AB',
    stats: ['hits', 'at_bats'],
    separator: '/',
  },
  {
    label: 'R',
    stats: ['runs'],
  },
  {
    label: 'HR',
    stats: ['home_runs'],
  },
  {
    label: 'RBI',
    stats: ['runs_batted_in'],
  },
  {
    label: 'BB',
    stats: ['walks'],
  },
];

const BASKETBALL_PLAYER = [
  {
    label: 'PTS',
    stats: ['points'],
  },
  {
    label: 'REB',
    stats: ['rebounds_total'],
  },
  {
    label: 'AST',
    stats: ['assists'],
  },
  {
    label: 'STL',
    stats: ['steals'],
  },
  {
    label: 'BLK',
    stats: ['blocked_shots'],
  },
];

const BASKETBALL_PLAYER_PREGAME = [
  {
    label: 'PTS',
    stats: ['points_average'],
  },
  {
    label: 'REB',
    stats: ['rebounds_total_average'],
  },
  {
    label: 'AST',
    stats: ['assists_average'],
  },
  {
    label: 'STL',
    stats: ['steals_average'],
  },
  {
    label: 'BLK',
    stats: ['blocked_shots_average'],
  },
];

const FOOTBALL_QB = [
  {
    label: 'COMP/ATT',
    stats: ['passing_completions', 'passing_attempts'],
    separator: '/',
  },
  {
    label: 'Yds',
    stats: ['passing_yards'],
  },
  {
    label: 'TD',
    stats: ['passing_touchdowns'],
  },
  {
    label: 'INT',
    stats: ['passing_interceptions'],
  },
  {
    label: 'RAT',
    stats: ['passing_rating'],
  },
];

const FOOTBALL_RB = [
  {
    label: 'CAR',
    stats: ['rushing_attempts'],
  },
  {
    label: 'Yds',
    stats: ['rushing_yards'],
  },
  {
    label: 'AVG',
    stats: ['rushing_yards_average'],
  },
  {
    label: 'TD',
    stats: ['rushing_touchdowns'],
  },
  {
    label: 'FUML',
    stats: ['fumbles_lost'],
  },
];

const FOOTBALL_RECEIVER = [
  {
    label: 'REC',
    stats: ['receiving_receptions'],
  },
  {
    label: 'TGT',
    stats: ['receiving_targets'],
  },
  {
    label: 'Yds',
    stats: ['receiving_yards'],
  },
  {
    label: 'AVG',
    stats: ['receiving_yards_average'],
  },
  {
    label: 'TD',
    stats: ['receiving_touchdowns'],
  },
];

const FOOTBALL_DEFENSE = [
  {
    label: 'TACK',
    stats: ['defensive_tackles_total'],
  },
  {
    label: 'SACK',
    stats: ['defensive_sacks'],
  },
  {
    label: 'INT',
    stats: ['interceptions'],
  },
  {
    label: 'FUMR',
    stats: ['fumbles_opponent_recovered'],
  },
  {
    label: 'FFUM',
    stats: ['fumbles_forced'],
  },
];

const FOOTBALL_KICKER = [
  {
    label: 'XP',
    stats: ['kicking_extra_points_made'],
  },
  {
    label: 'XPA',
    stats: ['kicking_extra_points_attempted'],
  },
  {
    label: 'FG',
    stats: ['field_goals_made'],
  },
  {
    label: 'FGA',
    stats: ['field_goals_attempted'],
  },
  {
    label: 'LNG',
    stats: ['field_goals_long'],
  },
];

const FOOTBALL_PUNTER = [
  {
    label: 'PUNT',
    stats: ['punts'],
  },
  {
    label: 'Yds',
    stats: ['punts_yards'],
  },
  {
    label: 'AVG',
    stats: ['punts_average'],
  },
  {
    label: 'LNG',
    stats: ['punts_yard_long'],
  },
  {
    label: 'TB',
    stats: ['punts_touchbacks'],
  },
];

const HOCKEY_GOALIE = [
  {
    label: 'SA',
    stats: ['shots_against'],
  },
  {
    label: 'GA',
    stats: ['goals_against'],
  },
  {
    label: 'SV',
    stats: ['saves'],
  },
  {
    label: 'SV%',
    stats: ['save_percentage'],
  },
];

const HOCKEY_GOALIE_PRE_GAME = [
  {
    label: 'Record',
    stats: ['record'],
  },
  {
    label: 'GAA',
    stats: ['goals_against_average'],
  },
  {
    label: 'SV%',
    stats: ['save_percentage'],
  },
  {
    label: 'SO',
    stats: ['shutouts'],
  },
];

const HOCKEY_SKATER = [
  {
    label: 'G',
    stats: ['goals'],
  },
  {
    label: 'A',
    stats: ['assists'],
  },
  {
    label: 'PIM',
    stats: ['penalty_minutes'],
  },
  {
    label: '+/-',
    stats: ['plus_minus'],
  },
  {
    label: 'TOI',
    stats: ['time_on_ice_full'],
  },
];

const SOCCER_PLAYER = [
  {
    label: 'G',
    stats: ['games_goals'],
  },
  {
    label: 'A',
    stats: ['games_assists'],
  },
  {
    label: 'SH',
    stats: ['shots'],
  },
  {
    label: 'SOG',
    stats: ['shots_on_goal'],
  },
  {
    label: 'PKG',
    stats: ['penalty_kick_goals'],
  },
];

const SOCCER_ATTACKER_PREGAME = [
  {
    label: 'G',
    stats: ['games_goals'],
  },
  {
    label: 'A',
    stats: ['games_assists'],
  },
  {
    label: 'SH',
    stats: ['games_shots'],
  },
  {
    label: 'SOG',
    stats: ['shots_on_goal'],
  },
  {
    label: 'PKG',
    stats: ['penalty_kick_goals'],
  },
];

const SOCCER_MIDFIELD_PREGAME = [
  {
    label: 'G',
    stats: ['games_goals'],
  },
  {
    label: 'A',
    stats: ['games_assists'],
  },
  {
    label: 'KEY',
    stats: ['key_passes'],
  },
  {
    label: 'CRS',
    stats: ['accurate_crosses'],
  },
  {
    label: 'COR',
    stats: ['corner_kicks'],
  },
];

const SOCCER_DEFENDER_PREGAME = [
  {
    label: 'G',
    stats: ['games_goals'],
  },
  {
    label: 'A',
    stats: ['games_assists'],
  },
  {
    label: 'TCK',
    stats: ['tackles_won'],
  },
  {
    label: 'INT',
    stats: ['touches_interceptions'],
  },
  {
    label: 'BLK',
    stats: ['blocked_shots'],
  },
];

const SOCCER_GOALIE_PREGAME = [
  {
    label: 'SV',
    stats: ['saves'],
  },
  {
    label: 'GC',
    stats: ['goals_allowed'],
  },
  {
    label: 'CS',
    stats: ['clean_sheets'],
  },
  {
    label: 'PAS',
    stats: ['touches_passes'],
  },
  {
    label: 'TCH',
    stats: ['touches'],
  },
];

const SOCCER_ATTACKER = [
  {
    label: 'G',
    stats: ['goals'],
  },
  {
    label: 'A',
    stats: ['assists'],
  },
  {
    label: 'SH',
    stats: ['shots'],
  },
  {
    label: 'SOG',
    stats: ['shots_on_goal'],
  },
  {
    label: 'PKG',
    stats: ['penalty_kick_goals'],
  },
];

const SOCCER_MIDFIELD = [
  {
    label: 'G',
    stats: ['goals'],
  },
  {
    label: 'A',
    stats: ['assists'],
  },
  {
    label: 'KEY',
    stats: ['key_passes'],
  },
  {
    label: 'CRS',
    stats: ['crosses'],
  },
  {
    label: 'COR',
    stats: ['corner_kicks'],
  },
];

const SOCCER_DEFENDER = [
  {
    label: 'G',
    stats: ['goals'],
  },
  {
    label: 'A',
    stats: ['assists'],
  },
  {
    label: 'TCK',
    stats: ['tackles_won'],
  },
  {
    label: 'INT',
    stats: ['touches_interceptions'],
  },
  {
    label: 'BLK',
    stats: ['touches_blocks'],
  },
];

const SOCCER_GOALIE = [
  {
    label: 'SV',
    stats: ['saves'],
  },
  {
    label: 'GC',
    stats: ['goals_against'],
  },
  {
    label: 'CS',
    stats: ['clean_sheets'],
  },
  {
    label: 'PAS',
    stats: ['touches_passes'],
  },
  {
    label: 'TCH',
    stats: ['touches'],
  },
];

export default {
  BASEBALL_PITCHER,
  BASEBALL_PITCHER_PREGAME,
  BASEBALL_PLAYER,
  BASKETBALL_PLAYER,
  BASKETBALL_PLAYER_PREGAME,
  FOOTBALL_DEFENSE,
  FOOTBALL_KICKER,
  FOOTBALL_PUNTER,
  FOOTBALL_QB,
  FOOTBALL_RB,
  FOOTBALL_RECEIVER,
  HOCKEY_GOALIE,
  HOCKEY_GOALIE_PRE_GAME,
  HOCKEY_SKATER,
  SOCCER_PLAYER,
  SOCCER_ATTACKER,
  SOCCER_MIDFIELD,
  SOCCER_DEFENDER,
  SOCCER_GOALIE,
  SOCCER_ATTACKER_PREGAME,
  SOCCER_MIDFIELD_PREGAME,
  SOCCER_DEFENDER_PREGAME,
  SOCCER_GOALIE_PREGAME,
};
