const BASEBALL_PITCHER = 'P';
const FOOTBALL_CORNER_BACK = 'CB';
const FOOTBALL_DEFENSIVE_BACK = 'DB';
const FOOTBALL_DEFENSIVE_END = 'DE';
const FOOTBALL_DEFENSIVE_LINEMAN = 'DL';
const FOOTBALL_DEFENSIVE_TACKLE = 'DT';
const FOOTBALL_FULL_BACK = 'FB';
const FOOTBALL_KICKER = 'K';
const FOOTBALL_LINEBACKER = 'LB';
const FOOTBALL_NOSE_TACKLE = 'NT';
const FOOTBALL_PUNTER = 'P';
const FOOTBALL_QUARTERBACK = 'QB';
const FOOTBALL_RUNNING_BACK = 'RB';
const FOOTBALL_SAFETY = 'S';
const FOOTBALL_SLOT_BACK = 'SB';
const FOOTBALL_TIGHT_END = 'TE';
const FOOTBALL_WIDE_RECEIVER = 'WR';
const HOCKEY_GOALIE = 'G';
const SOCCER_GOALIE = 'G';
const SOCCER_DEFENDER = 'D';
const SOCCER_MIDFIELDER = 'M';
const SOCCER_FORWARD = 'F';

export default {
  BASEBALL_PITCHER,
  FOOTBALL_CORNER_BACK,
  FOOTBALL_DEFENSIVE_BACK,
  FOOTBALL_DEFENSIVE_END,
  FOOTBALL_DEFENSIVE_LINEMAN,
  FOOTBALL_DEFENSIVE_TACKLE,
  FOOTBALL_FULL_BACK,
  FOOTBALL_KICKER,
  FOOTBALL_LINEBACKER,
  FOOTBALL_NOSE_TACKLE,
  FOOTBALL_PUNTER,
  FOOTBALL_QUARTERBACK,
  FOOTBALL_RUNNING_BACK,
  FOOTBALL_SAFETY,
  FOOTBALL_SLOT_BACK,
  FOOTBALL_TIGHT_END,
  FOOTBALL_WIDE_RECEIVER,
  HOCKEY_GOALIE,
  SOCCER_GOALIE,
  SOCCER_DEFENDER,
  SOCCER_MIDFIELDER,
  SOCCER_FORWARD,
};
