const BASEBALL_PITCHER = [
  {
    label: 'Games',
    stats: ['games_pitched'],
  },
  {
    label: 'GS',
    stats: ['games_started'],
  },
  {
    label: 'CG',
    stats: ['complete_games'],
  },
  {
    label: 'SHO',
    stats: ['shut_outs'],
  },
  {
    label: 'IP',
    stats: ['innings_pitched'],
  },
  {
    label: 'W-L',
    stats: ['wins', 'losses'],
    separator: '-',
  },
  {
    label: 'ERA',
    stats: ['earned_run_average'],
  },
  {
    label: 'WHIP',
    stats: ['walks_and_hits_per_inning'],
  },
  {
    label: 'K',
    stats: ['strike_outs_thrown'],
  },
  {
    label: 'BB',
    stats: ['total_walks_allowed'],
  },
  {
    label: 'ER',
    stats: ['earned_runs_allowed'],
  },
  {
    label: 'R',
    stats: ['runs_allowed'],
  },
  {
    label: 'H',
    stats: ['hits_allowed'],
  },
  {
    label: 'HR',
    stats: ['home_runs'],
  },
  {
    label: 'SV',
    stats: ['saves'],
  },
  {
    label: 'BS',
    stats: ['blown_saves'],
  },
  {
    label: 'WAR',
    stats: ['war'],
  },
];

const BASEBALL_PLAYER = [
  {
    label: 'Games',
    stats: ['games'],
  },
  {
    label: 'PA',
    stats: ['plate_appearances'],
  },
  {
    label: 'AB',
    stats: ['at_bats'],
  },
  {
    label: 'HBP',
    stats: ['hit_by_pitch'],
  },
  {
    label: 'AVG',
    stats: ['batting_average'],
  },
  {
    label: 'OBP',
    stats: ['on_base_percentage'],
  },
  {
    label: 'SLG',
    stats: ['slugging_percentage'],
  },
  {
    label: 'OPS',
    stats: ['on_base_plus_slugging'],
  },
  {
    label: 'H',
    stats: ['hits'],
  },
  {
    label: 'HR',
    stats: ['home_runs'],
  },
  {
    label: '2B',
    stats: ['doubles'],
  },
  {
    label: '3B',
    stats: ['triple'],
  },
  {
    label: 'R',
    stats: ['runs'],
  },
  {
    label: 'RBI',
    stats: ['runs_batted_in'],
  },
  {
    label: 'SO',
    stats: ['strike_outs'],
  },
  {
    label: 'BB',
    stats: ['walks'],
  },
  {
    label: 'SB',
    stats: ['stolen_bases'],
  },
  {
    label: 'CS',
    stats: ['caught_stealing'],
  },
  {
    label: 'WAR',
    stats: ['war'],
  },
];

const BASKETBALL_PLAYER = [
  {
    label: 'Games',
    stats: ['games'],
  },
  {
    label: 'Games Started',
    stats: ['games_started'],
  },
  {
    label: 'MPG',
    stats: ['minutes_average'],
  },
  {
    label: 'PER',
    stats: ['player_efficiency_rating'],
  },
  {
    label: 'PPG',
    stats: ['points_average'],
  },
  {
    label: 'APG',
    stats: ['assists_average'],
  },
  {
    label: 'REB',
    stats: ['rebounds_total_average'],
  },
  {
    label: 'TOV',
    stats: ['turnovers_average'],
  },
  {
    label: 'TS%',
    stats: ['true_shooting_percentage'],
  },
  {
    label: 'FG%',
    stats: ['field_goals_percentage'],
  },
  {
    label: '3PT%',
    stats: ['three_point_field_goals_percentage'],
  },
  {
    label: 'FT%',
    stats: ['free_throws_percentage'],
  },
  {
    label: 'STL',
    stats: ['steals_average'],
  },
  {
    label: 'BLK',
    stats: ['blocked_shots_average'],
  },
  {
    label: 'PF',
    stats: ['personal_fouls_average'],
  },
  {
    label: 'USG%',
    stats: ['usage_percentage'],
  },
];

const FOOTBALL_GENERAL = [
  {
    label: 'Games Started',
    stats: ['games_started'],
  },
  {
    label: 'Games Played',
    stats: ['games'],
  },
];

const FOOTBALL_PASSING = [
  {
    label: 'ATT',
    stats: ['passing_attempts'],
  },
  {
    label: 'COMP',
    stats: ['passing_completions'],
  },
  {
    label: 'PCT',
    stats: ['passing_completions_percentage'],
  },
  {
    label: 'RATE',
    stats: ['quarterback_rating'],
  },
  {
    label: 'Yds',
    stats: ['passing_yards'],
  },
  {
    label: 'Yds/G',
    stats: ['passing_yards_per_game'],
  },
  {
    label: 'TD',
    stats: ['passing_touchdowns'],
  },
  {
    label: 'INT',
    stats: ['interceptions'],
  },
  {
    label: 'SCK',
    stats: ['sacked'],
  },
  {
    label: 'SYD',
    stats: ['sacked_yards'],
  },
  {
    label: 'FUM',
    stats: ['passing_fumbles'],
  },
  {
    label: 'LST',
    stats: ['passing_fumbles_lost'],
  },
  {
    label: 'AVG',
    stats: ['passing_yards_per_attempt'],
  },
  {
    label: 'LNG',
    stats: ['passing_long_yards'],
  },
  {
    label: 'TD%',
    stats: ['passing_touchdowns_percentage'],
  },
  {
    label: 'INT%',
    stats: ['interceptions_percentage'],
  },
  {
    label: '1ST',
    stats: ['passing_first_downs'],
  },
  {
    label: '1ST%',
    stats: ['passing_first_downs_percentage'],
  },
];

const FOOTBALL_RUSHING = [
  {
    label: 'ATT',
    stats: ['rushing_attempts'],
  },
  {
    label: 'YDS',
    stats: ['rushing_yards'],
  },
  {
    label: 'AVG',
    stats: ['rushing_yards_per_attempt'],
  },
  {
    label: 'TD',
    stats: ['rushing_touchdowns'],
  },
  {
    label: 'ATT/G',
    stats: ['rushing_attempts_per_game'],
  },
  {
    label: 'YDS/G',
    stats: ['rushing_yards_per_game'],
  },
  {
    label: 'FUM',
    stats: ['rushing_fumbles'],
  },
  {
    label: 'LST',
    stats: ['rushing_fumbles_lost'],
  },
  {
    label: '1ST',
    stats: ['rushing_first_downs'],
  },
  {
    label: '1ST%',
    stats: ['rushing_first_downs_percentage'],
  },
  {
    label: 'LNG',
    stats: ['rushing_long_yards'],
  },
];

const FOOTBALL_RECEIVING = [
  {
    label: 'REC',
    stats: ['receptions'],
  },
  {
    label: 'YDS',
    stats: ['receiving_yards'],
  },
  {
    label: 'AVG',
    stats: ['receiving_yards_average'],
  },
  {
    label: 'TD',
    stats: ['receiving_touchdowns'],
  },
  {
    label: 'YDS/G',
    stats: ['receiving_yards_per_game'],
  },
  {
    label: '1ST',
    stats: ['receiving_first_downs'],
  },
  {
    label: '1ST%',
    stats: ['receiving_first_downs_percentage'],
  },
  {
    label: 'TD%',
    stats: ['receiving_touchdowns_percentage'],
  },
  {
    label: 'TGT',
    stats: ['receiving_targets'],
  },
  {
    label: 'DROP',
    stats: ['receiving_drops'],
  },
  {
    label: 'FUM',
    stats: ['receiving_fumbles'],
  },
  {
    label: 'LST',
    stats: ['receiving_fumbles_lost'],
  },
];

const FOOTBALL_KICKING_FIELD_GOALS = [
  {
    label: 'FGA',
    stats: ['field_goals_attempted'],
  },
  {
    label: 'FGM',
    stats: ['field_goals_made'],
  },
  {
    label: 'LNG',
    stats: ['long_field_goal_yards'],
  },
  {
    label: 'PCT',
    stats: ['field_goals_percentage'],
  },
  {
    label: 'XPA',
    stats: ['extra_points_attempted'],
  },
  {
    label: 'XPM',
    stats: ['extra_points_made'],
  },
  {
    label: 'FGA 0-19',
    stats: ['field_goals_0_19_attempted'],
  },
  {
    label: 'FGM 0-19',
    stats: ['field_goals_0_19_made'],
  },
  {
    label: 'FGA 20-29',
    stats: ['field_goals_20_29_attempted'],
  },
  {
    label: 'FGM 20-29',
    stats: ['field_goals_20_29_made'],
  },
  {
    label: 'FGA 30-39',
    stats: ['field_goals_0_19_attempted'],
  },
  {
    label: 'FGM 30-39',
    stats: ['field_goals_30_39_made'],
  },
  {
    label: 'FGA 40-49',
    stats: ['field_goals_40_49_attempted'],
  },
  {
    label: 'FGM 40-49',
    stats: ['field_goals_40_49_made'],
  },
  {
    label: 'FGA 50+',
    stats: ['field_goals_50_plus_attempted'],
  },
  {
    label: 'FGM 50+',
    stats: ['field_goals_50_plus_made'],
  },
];

const FOOTBALL_KICKING_KICKOFFS = [
  {
    label: 'KO',
    stats: ['kickoffs'],
  },
  {
    label: 'YDS',
    stats: ['kickoff_yards'],
  },
  {
    label: 'AVG',
    stats: ['kickoff_yards_average'],
  },
  {
    label: 'TB',
    stats: ['kickoff_touchbacks'],
  },
  {
    label: 'RET',
    stats: ['kickoff_returns'],
  },
  {
    label: 'RETY',
    stats: ['kickoff_return_yards'],
  },
];

const FOOTBALL_PUNTING = [
  {
    label: 'PUNTS',
    stats: ['punts'],
  },
  {
    label: 'YDS',
    stats: ['punts_yards'],
  },
  {
    label: 'AVG',
    stats: ['punts_average'],
  },
  {
    label: 'LNG',
    stats: ['long_yards'],
  },
  {
    label: 'NET AVG',
    stats: ['punts_net_average'],
  },
  {
    label: 'BLK',
    stats: ['blocked_punts'],
  },
  {
    label: 'IN 10',
    stats: ['inside_10_punts'],
  },
  {
    label: 'IN 20',
    stats: ['inside_20_punts'],
  },
  {
    label: 'FC',
    stats: ['fair_catches'],
  },
  {
    label: 'RET',
    stats: ['punt_returns'],
  },
  {
    label: 'RETY',
    stats: ['punt_return_yards'],
  },
  {
    label: 'TB',
    stats: ['touchbacks'],
  },
];

const FOOTBALL_DEFENSE_SACKS_AND_TACKLES = [
  {
    label: 'SOLO',
    stats: ['tackles'],
  },
  {
    label: 'AST',
    stats: ['tackles_assist'],
  },
  {
    label: 'TOT',
    stats: ['total_tackles'],
  },
  {
    label: 'SFTY',
    stats: ['safeties'],
  },
  {
    label: 'SCK',
    stats: ['sacks'],
  },
  {
    label: 'SYD',
    stats: ['sack_yards'],
  },
  {
    label: 'STF',
    stats: ['stuffs'],
  },
  {
    label: 'HRY',
    stats: 'hurries',
  },
];

const FOOTBALL_DEFENSE_INTERCEPTIONS = [
  {
    label: 'INT',
    stats: ['interceptions'],
  },
  {
    label: 'YDS',
    stats: ['interception_yards'],
  },
  {
    label: 'LNG',
    stats: ['interception_long_yards'],
  },
  {
    label: 'TD',
    stats: ['interception_touchdowns'],
  },
  {
    label: 'PDEF',
    stats: ['passes_defensed'],
  },
];

const FOOTBALL_DEFENSE_FUMBLES = [
  {
    label: 'FF',
    stats: ['forced_fumbles'],
  },
  {
    label: 'REC',
    stats: ['fumbles_recovered'],
  },
  {
    label: 'YDS',
    stats: ['fumbles_recovered_yards'],
  },
  {
    label: 'TD',
    stats: ['fumbles_recovered_touchdowns'],
  },
];

const HOCKEY_GOALIE = [
  {
    label: 'Games Played',
    stats: ['games_played'],
  },
  {
    label: 'Games Started',
    stats: ['games_started'],
  },
  {
    label: 'Goals Against',
    stats: ['goals_against'],
  },
  {
    label: 'SV',
    stats: ['saves'],
  },
  {
    label: 'Wins',
    stats: ['wins'],
  },
  {
    label: 'Losses',
    stats: ['losses'],
  },
  {
    label: 'OTL',
    stats: ['overtime_losses'],
  },
  {
    label: 'SO',
    stats: ['shutouts'],
  },
  {
    label: 'GAA',
    stats: ['goals_against_average'],
  },
  {
    label: 'SV%',
    stats: ['save_percentage'],
  },
  {
    label: 'SO SV%',
    stats: ['shootout_save_percentage'],
  },
  {
    label: 'MIN',
    stats: ['time_on_ice'],
  },
  {
    label: 'First Stars',
    stats: ['first_stars'],
  },
  {
    label: 'Second Stars',
    stats: ['second_stars'],
  },
  {
    label: 'Third Stars',
    stats: ['third_stars'],
  },
];

const HOCKEY_SKATER = [
  {
    label: 'Games Played',
    stats: ['games_played'],
  },
  {
    label: 'G',
    stats: ['goals'],
  },
  {
    label: 'A',
    stats: ['assists'],
  },
  {
    label: 'Points',
    stats: ['points'],
  },
  {
    label: 'TOI',
    stats: ['time_on_ice_minutes'],
  },
  {
    label: 'PP TOI',
    stats: ['power_play_time_on_ice'],
  },
  {
    label: 'PPG',
    stats: ['power_player_goals'],
  },
  {
    label: 'PPP',
    stats: ['power_play_points'],
  },
  {
    label: 'HIT',
    stats: ['hits'],
  },
  {
    label: 'BLK',
    stats: ['blocks'],
  },
  {
    label: 'PIM',
    stats: ['penalty_minutes'],
  },
  {
    label: 'SHG',
    stats: ['shootout_goals'],
  },
  {
    label: 'SH',
    stats: ['shooutout_goal_attempts'],
  },
  {
    label: 'SH%',
    stats: ['shootout_scoring_percentage'],
  },
  {
    label: '+/-',
    stats: ['plus_minus'],
  },
  {
    label: 'FO%',
    stats: ['faceoff_winning_percentage'],
  },
  {
    label: 'GV',
    stats: ['turnover_giveaways'],
  },
  {
    label: 'TK',
    stats: ['turnover_takeaways'],
  },
  {
    label: 'First Stars',
    stats: ['first_stars'],
  },
  {
    label: 'Second Stars',
    stats: ['second_stars'],
  },
  {
    label: 'Third Stars',
    stats: ['third_stars'],
  },
];

const SOCCER_GOALIE = [
  {
    label: 'GP',
    stats: ['games_played'],
  },
  {
    label: 'GS',
    stats: ['games_started'],
  },
  {
    label: 'MINS',
    stats: ['minutes'],
  },
  {
    label: 'GC',
    stats: ['goals_allowed'],
  },
  {
    label: 'CS',
    stats: ['clean_sheets'],
  },
  {
    label: 'ShF',
    stats: ['shots_faced'],
  },
  {
    label: 'S',
    stats: ['saves'],
  },
  {
    label: 'FC',
    stats: ['fouls_committed'],
  },
  {
    label: 'FS',
    stats: ['fouls_suffered'],
  },
  {
    label: 'Y',
    stats: ['yellow_cards'],
  },
  {
    label: 'R',
    stats: ['red_cards'],
  },
  {
    label: 'TCK',
    stats: ['tackles'],
  },
  {
    label: 'INT',
    stats: ['touches_interceptions'],
  },
];

const SOCCER_PLAYER = [
  {
    label: 'GP',
    stats: ['games_played'],
  },
  {
    label: 'GS',
    stats: ['games_started'],
  },
  {
    label: 'MINS',
    stats: ['minutes'],
  },
  {
    label: 'GWG',
    stats: ['game_winning_goals'],
  },
  {
    label: 'G',
    stats: ['games_goals'],
  },
  {
    label: 'A',
    stats: ['assists'],
  },
  {
    label: 'SHT',
    stats: ['games_shots'],
  },
  {
    label: 'SOG',
    stats: ['games_shots_on_goal'],
  },
  {
    label: 'FC',
    stats: ['fouls_committed'],
  },
  {
    label: 'FS',
    stats: ['fouls_suffered'],
  },
  {
    label: 'Y',
    stats: ['yellow_cards'],
  },
  {
    label: 'R',
    stats: ['red_cards'],
  },
  {
    label: 'PKA',
    stats: ['penalty_kick_shots'],
  },
  {
    label: 'PKG',
    stats: ['penalty_kick_shots'],
  },
  {
    label: 'CRN',
    stats: ['corner_kicks'],
  },
  {
    label: 'CRS',
    stats: ['crosses'],
  },
  {
    label: 'OFF',
    stats: ['offsides'],
  },
  {
    label: 'OG',
    stats: ['own_goals'],
  },
  {
    label: 'TCK',
    stats: ['tackles'],
  },
  {
    label: 'INT',
    stats: ['touches_interceptions'],
  },
];

export default {
  BASEBALL_PITCHER,
  BASEBALL_PLAYER,
  BASKETBALL_PLAYER,
  FOOTBALL_GENERAL,
  FOOTBALL_PASSING,
  FOOTBALL_RUSHING,
  FOOTBALL_RECEIVING,
  FOOTBALL_PUNTING,
  FOOTBALL_KICKING_FIELD_GOALS,
  FOOTBALL_KICKING_KICKOFFS,
  FOOTBALL_DEFENSE_SACKS_AND_TACKLES,
  FOOTBALL_DEFENSE_INTERCEPTIONS,
  FOOTBALL_DEFENSE_FUMBLES,
  HOCKEY_GOALIE,
  HOCKEY_SKATER,
  SOCCER_GOALIE,
  SOCCER_PLAYER,
};
