const BASEBALL = 'baseball';
const BASKETBALL = 'basketball';
const FOOTBALL = 'football';
const HOCKEY = 'hockey';
const SOCCER = 'soccer';

export default {
  BASEBALL,
  BASKETBALL,
  FOOTBALL,
  HOCKEY,
  SOCCER,
};
