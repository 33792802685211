import PlayerStats from '~/constants/PlayerStats';
import KeyPlayerStats from '~/constants/KeyPlayerStats';
import PlayerOverviewStats from '~/constants/PlayerOverviewStats';
import PlayerSeasonStats from '~/constants/PlayerSeasonStats';
import PlayerPositions from '~/constants/PlayerPositions';
import Sports from '~/constants/Sports';
import get from 'lodash/get';

const sport = player => get(player, 'resource_uri', '/').split('/')[1];

const formattedStat = (stat, statsRecord) => {
  let rawStats = [];

  if (stat && stat.stats) {
    rawStats = Array.isArray(stat.stats)
      ? stat.stats.map(rawStat => get(statsRecord, rawStat, '-'))
      : [get(statsRecord, stat.stats, '-')];
  }

  if (stat && stat.execute_calculation && rawStats.length) {
    return stat.calculation(rawStats);
  }

  return rawStats.join(stat.separator);
};

const normalizeStats = (stats, statsRecord) =>
  stats.map(stat => ({
    label: stat.label,
    stat: formattedStat(stat, statsRecord),
  }));

const normalizeBaseballStats = (
  player,
  statsRecord,
  statsFormats: any = PlayerStats,
  positionOverride
) => {
  if (player.position_abbreviation === 'P' || positionOverride === 'P') {
    return normalizeStats(statsFormats.BASEBALL_PITCHER, statsRecord);
  }
  return normalizeStats(statsFormats.BASEBALL_PLAYER, statsRecord);
};

const normalizeBasketballStats = (statsRecord, statsFormats: any = PlayerStats) =>
  normalizeStats(statsFormats.BASKETBALL_PLAYER, statsRecord);

const normalizeFootballStats = (player, statsRecord, statsFormats: any = PlayerStats) => {
  switch (player.position_abbreviation) {
    case PlayerPositions.FOOTBALL_QUARTERBACK:
      return normalizeStats(statsFormats.FOOTBALL_QB, statsRecord);
    case PlayerPositions.FOOTBALL_RUNNING_BACK:
    case PlayerPositions.FOOTBALL_FULL_BACK:
      return normalizeStats(statsFormats.FOOTBALL_RB, statsRecord);
    case PlayerPositions.FOOTBALL_WIDE_RECEIVER:
    case PlayerPositions.FOOTBALL_TIGHT_END:
    case PlayerPositions.FOOTBALL_SLOT_BACK:
      return normalizeStats(statsFormats.FOOTBALL_RECEIVER, statsRecord);
    case PlayerPositions.FOOTBALL_NOSE_TACKLE:
    case PlayerPositions.FOOTBALL_LINEBACKER:
    case PlayerPositions.FOOTBALL_DEFENSIVE_END:
    case PlayerPositions.FOOTBALL_DEFENSIVE_TACKLE:
    case PlayerPositions.FOOTBALL_DEFENSIVE_BACK:
    case PlayerPositions.FOOTBALL_DEFENSIVE_LINEMAN:
    case PlayerPositions.FOOTBALL_CORNER_BACK:
    case PlayerPositions.FOOTBALL_SAFETY:
      return normalizeStats(statsFormats.FOOTBALL_DEFENSE, statsRecord);
    case PlayerPositions.FOOTBALL_KICKER:
      return normalizeStats(statsFormats.FOOTBALL_KICKER, statsRecord);
    case PlayerPositions.FOOTBALL_PUNTER:
      return normalizeStats(statsFormats.FOOTBALL_PUNTER, statsRecord);
    default:
      return [];
  }
};

const normalizeFootballSeasonStats = (player, statsRecord) => {
  switch (player.position_abbreviation) {
    case PlayerPositions.FOOTBALL_QUARTERBACK:
      return {
        general: normalizeStats(PlayerSeasonStats.FOOTBALL_GENERAL, statsRecord),
        passing: normalizeStats(PlayerSeasonStats.FOOTBALL_PASSING, statsRecord),
        rushing: normalizeStats(PlayerSeasonStats.FOOTBALL_RUSHING, statsRecord),
      };
    case PlayerPositions.FOOTBALL_RUNNING_BACK:
    case PlayerPositions.FOOTBALL_FULL_BACK:
      return {
        general: normalizeStats(PlayerSeasonStats.FOOTBALL_GENERAL, statsRecord),
        receiving: normalizeStats(PlayerSeasonStats.FOOTBALL_RECEIVING, statsRecord),
        rushing: normalizeStats(PlayerSeasonStats.FOOTBALL_RUSHING, statsRecord),
      };
    case PlayerPositions.FOOTBALL_WIDE_RECEIVER:
    case PlayerPositions.FOOTBALL_TIGHT_END:
    case PlayerPositions.FOOTBALL_SLOT_BACK:
      return {
        general: normalizeStats(PlayerSeasonStats.FOOTBALL_GENERAL, statsRecord),
        receiving: normalizeStats(PlayerSeasonStats.FOOTBALL_RECEIVING, statsRecord),
        rushing: normalizeStats(PlayerSeasonStats.FOOTBALL_RUSHING, statsRecord),
      };
    case PlayerPositions.FOOTBALL_NOSE_TACKLE:
    case PlayerPositions.FOOTBALL_LINEBACKER:
    case PlayerPositions.FOOTBALL_DEFENSIVE_END:
    case PlayerPositions.FOOTBALL_DEFENSIVE_TACKLE:
    case PlayerPositions.FOOTBALL_DEFENSIVE_BACK:
    case PlayerPositions.FOOTBALL_DEFENSIVE_LINEMAN:
    case PlayerPositions.FOOTBALL_CORNER_BACK:
    case PlayerPositions.FOOTBALL_SAFETY:
      return {
        general: normalizeStats(PlayerSeasonStats.FOOTBALL_GENERAL, statsRecord),
        sacks_and_tackles: normalizeStats(
          PlayerSeasonStats.FOOTBALL_DEFENSE_SACKS_AND_TACKLES,
          statsRecord
        ),
        interceptions: normalizeStats(
          PlayerSeasonStats.FOOTBALL_DEFENSE_INTERCEPTIONS,
          statsRecord
        ),
        fumbles: normalizeStats(PlayerSeasonStats.FOOTBALL_DEFENSE_FUMBLES, statsRecord),
      };
    case PlayerPositions.FOOTBALL_KICKER:
      return {
        general: normalizeStats(PlayerSeasonStats.FOOTBALL_GENERAL, statsRecord),
        field_goals: normalizeStats(PlayerSeasonStats.FOOTBALL_KICKING_FIELD_GOALS, statsRecord),
        kick_offs: normalizeStats(PlayerSeasonStats.FOOTBALL_KICKING_KICKOFFS, statsRecord),
      };
    case PlayerPositions.FOOTBALL_PUNTER:
      return {
        general: normalizeStats(PlayerSeasonStats.FOOTBALL_GENERAL, statsRecord),
        punting: normalizeStats(PlayerSeasonStats.FOOTBALL_PUNTING, statsRecord),
      };
    default:
      return {};
  }
};

const normalizeHockeyStats = (player, statsRecord, statsFormats: any = PlayerStats) => {
  if (player.position_abbreviation === PlayerPositions.HOCKEY_GOALIE) {
    return normalizeStats(statsFormats.HOCKEY_GOALIE, statsRecord);
  }
  return normalizeStats(statsFormats.HOCKEY_SKATER, statsRecord);
};

const normalizeSoccerStats = (player, statsRecord, statsFormats: any = PlayerStats) => {
  if (player.position_abbreviation === PlayerPositions.SOCCER_GOALIE) {
    return normalizeStats(statsFormats.SOCCER_GOALIE, statsRecord);
  }
  if (
    statsFormats.SOCCER_DEFENDER &&
    player.position_abbreviation === PlayerPositions.SOCCER_DEFENDER
  ) {
    return normalizeStats(statsFormats.SOCCER_DEFENDER, statsRecord);
  }
  if (
    statsFormats.SOCCER_MIDFIELDER &&
    player.position_abbreviation === PlayerPositions.SOCCER_MIDFIELDER
  ) {
    return normalizeStats(statsFormats.SOCCER_MIDFIELDER, statsRecord);
  }
  if (
    statsFormats.SOCCER_FORWARD &&
    player.position_abbreviation === PlayerPositions.SOCCER_FORWARD
  ) {
    return normalizeStats(statsFormats.SOCCER_FORWARD, statsRecord);
  }
  return normalizeStats(statsFormats.SOCCER_PLAYER, statsRecord);
};

const normalizePlayerStats = (
  player: Object,
  statsRecord: Object,
  statsFormat: any = PlayerStats
) => {
  switch (sport(player)) {
    case Sports.BASEBALL:
      return normalizeBaseballStats(player, statsRecord, statsFormat, undefined);
    case Sports.BASKETBALL:
      return normalizeBasketballStats(statsRecord, statsFormat);
    case Sports.FOOTBALL:
      return normalizeFootballStats(player, statsRecord, statsFormat);
    case Sports.HOCKEY:
      return normalizeHockeyStats(player, statsRecord, statsFormat);
    case Sports.SOCCER:
      return normalizeSoccerStats(player, statsRecord, statsFormat);
    default:
      return [];
  }
};

export const normalizeKeyPlayerStats = (
  player: Object,
  statsRecord: Object,
  positionOverride = ''
) => {
  switch (sport(player)) {
    case Sports.BASEBALL:
      return normalizeBaseballStats(player, statsRecord, KeyPlayerStats, positionOverride);
    case Sports.BASKETBALL:
      return normalizeBasketballStats(statsRecord, KeyPlayerStats);
    case Sports.FOOTBALL:
      return normalizeFootballStats(player, statsRecord, KeyPlayerStats);
    case Sports.HOCKEY:
      return normalizeHockeyStats(player, statsRecord, KeyPlayerStats);
    case Sports.SOCCER:
      return normalizeSoccerStats(player, statsRecord, KeyPlayerStats);
    default:
      return [];
  }
};

export const normalizePlayerOverviewStats = (player: Object, statsRecord: Object) => {
  switch (sport(player)) {
    case Sports.BASEBALL:
      return normalizeBaseballStats(player, statsRecord, PlayerOverviewStats, undefined);
    case Sports.BASKETBALL:
      return normalizeBasketballStats(statsRecord, PlayerOverviewStats);
    case Sports.FOOTBALL:
      return normalizeFootballStats(player, statsRecord, PlayerOverviewStats);
    case Sports.HOCKEY:
      return normalizeHockeyStats(player, statsRecord, PlayerOverviewStats);
    case Sports.SOCCER:
      return normalizeSoccerStats(player, statsRecord, PlayerOverviewStats);
    default:
      return [];
  }
};

export const normalizePlayerSeasonStats = (player: Object, statsRecord: Object) => {
  switch (sport(player)) {
    case Sports.BASEBALL:
      return normalizeBaseballStats(player, statsRecord, PlayerSeasonStats, undefined);
    case Sports.BASKETBALL:
      return normalizeBasketballStats(statsRecord, PlayerSeasonStats);
    case Sports.FOOTBALL:
      return normalizeFootballSeasonStats(player, statsRecord);
    case Sports.HOCKEY:
      return normalizeHockeyStats(player, statsRecord, PlayerSeasonStats);
    case Sports.SOCCER:
      return normalizeSoccerStats(player, statsRecord, PlayerSeasonStats);
    default:
      return [];
  }
};

// @ts-ignore
export const normalizeBaseballPitcherStatsPreGame = (player: Object, statsRecord: Object) =>
  normalizeStats(KeyPlayerStats.BASEBALL_PITCHER_PREGAME, statsRecord);

// @ts-ignore
export const normalizeBasketballStatsPreGame = (player: Object, statsRecord: Object) =>
  normalizeStats(KeyPlayerStats.BASKETBALL_PLAYER_PREGAME, statsRecord);

export const normalizeSoccerStatsPreGame = (player: any, statsRecord: Object) => {
  if (player) {
    let playerStats;
    switch (player.position_abbreviation) {
      case PlayerPositions.SOCCER_FORWARD:
        playerStats = KeyPlayerStats.SOCCER_ATTACKER_PREGAME;
        break;
      case PlayerPositions.SOCCER_MIDFIELDER:
        playerStats = KeyPlayerStats.SOCCER_MIDFIELD_PREGAME;
        break;
      case PlayerPositions.SOCCER_DEFENDER:
        playerStats = KeyPlayerStats.SOCCER_DEFENDER_PREGAME;
        break;
      case PlayerPositions.SOCCER_GOALIE:
        playerStats = KeyPlayerStats.SOCCER_GOALIE_PREGAME;
        break;
      default:
        playerStats = KeyPlayerStats.SOCCER_PLAYER;
        break;
    }
    return normalizeStats(playerStats, statsRecord);
  }
  return [];
};

export const normalizeSoccerStatsLivePostGame = (player: any, statsRecord: Object) => {
  if (player) {
    let playerStats;
    switch (player.position_abbreviation) {
      case PlayerPositions.SOCCER_FORWARD:
        playerStats = KeyPlayerStats.SOCCER_ATTACKER;
        break;
      case PlayerPositions.SOCCER_MIDFIELDER:
        playerStats = KeyPlayerStats.SOCCER_MIDFIELD;
        break;
      case PlayerPositions.SOCCER_DEFENDER:
        playerStats = KeyPlayerStats.SOCCER_DEFENDER;
        break;
      case PlayerPositions.SOCCER_GOALIE:
        playerStats = KeyPlayerStats.SOCCER_GOALIE;
        break;
      default:
        playerStats = KeyPlayerStats.SOCCER_PLAYER;
        break;
    }
    return normalizeStats(playerStats, statsRecord);
  }
  return [];
};

export const normalizeHockeyStatsPreGame = (player: any, statsRecord: Object) => {
  if (player.position_abbreviation === PlayerPositions.HOCKEY_GOALIE) {
    return normalizeStats(KeyPlayerStats.HOCKEY_GOALIE_PRE_GAME, statsRecord);
  }
  return normalizeHockeyStats(player, statsRecord);
};

export default normalizePlayerStats;
